import React, { useState } from "react"
import ImageModule from "../../modules/image-render"
import "./TeamBannerComponent.scss"
import parse from "html-react-parser"
import { Container, Modal } from "react-bootstrap"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import DefaultForm from "../forms/default-form-layout"
import FormFields from "../../../static/forms/team_form.json"

const TeamBannerComponent = ({ teamData }) => {
  let processedImages = JSON.stringify({})
  if (teamData?.image?.imagetransforms) {
    processedImages = teamData?.image?.imagetransforms
  }

  const [show, setShow] = useState(false)

  const handleModal = () => {
    setShow(true)
  }

  const closeModal = () => {
    setShow(false)
  }



  const { isLargeScreen } = useDeviceMedia()
  var imagename = "team.image.full_image"
  return (
    <div className="team-banner-module">
      {isLargeScreen && (
        <div className="image-section img-zoom">
          <ImageModule
            ImageSrc={teamData?.image}
            altText={`${
              teamData?.image?.alternativeText
                ? teamData?.image?.alternativeText
                : teamData?.image?.name
            } banner`}
            imagetransforms={processedImages}
            renderer="pic-src"
            imagename={imagename}
            strapi_id={teamData.id}
            classNames="team-detail-image"
          />
        </div>
      )}
      {isLargeScreen && (teamData?.email || teamData?.phone) && (
        <div className="button-container">
          {teamData?.email &&
          <button onClick={handleModal} className="button button-filled">
            <icon className="icon icon-email-white" /> <span>Email</span>
          </button>}
          {teamData?.phone &&
          <a href={`tel:${teamData?.phone}`} className="button button-filled">
            <icon className="icon white-icon-mobile" />{" "}
            <span>{teamData?.phone}</span>
          </a>
          }
        </div>
      )}
      <Container>
        <div className="largedevice-team-container">
          <div className="team-content-section">
            <div className="team-member-info">
              <h1 className="title">{teamData?.name}</h1>
              <p className="designation">{teamData?.designation}</p>
              {!isLargeScreen && (
                <div className="button-container-desktop">
                  {teamData?.email &&
                  <button
                    onClick={handleModal}
                    className="button button-filled"
                  >
                    <icon className="icon icon-email-white" />{" "}
                    <span>Email</span>
                  </button>
                  }
                  {teamData?.phone &&
                  <a href={`tel:${teamData?.phone}`} className="button button-filled second">
                    <icon className="icon white-icon-mobile" />{" "}
                    <span>{teamData?.phone}</span>
                  </a>}
                  
                </div>
              )}
              {teamData?.content?.data?.content &&
              <div className="member-description">
                {parse(teamData?.content?.data?.content)}
              </div>
              }
            </div>
          </div>
          {!isLargeScreen && (
            <div className="image-section img-zoom">
              <ImageModule
                ImageSrc={teamData?.image}
                altText={`${
                  teamData?.image?.alternativeText
                    ? teamData?.image?.alternativeText
                    : teamData?.image?.name
                } banner`}
                imagetransforms={processedImages}
                renderer="pic-src"
                imagename={imagename}
                strapi_id={teamData?.id}
                classNames="team-detail-image"
              />
            </div>
          )}
        </div>
        <Modal
                    show={show}
                    onHide={() => setShow(false)}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="more-filters-modal contact-form"
                    id="filter-modal"
                    // fullscreen={"xl-down"}
                    backdrop="static"
                  >
                    <button
                      className="cancel-button"
                      type="button"
                      onClick={() => closeModal()}
                    >
                      <i className="icon black-cancel" />
                    </button>
                    <Modal.Body>
                      <div className="modal-body-container">
                        <h2>Open a Conversation</h2>
                        <div className="contact-text">
                          Please contact us using the form below or call us on{" "}
                          <a href={"tel:" + "+44 (0)207284 1222"}>
                            +44 (0)20 7284 1222
                          </a>
                          .
                        </div>
                        <DefaultForm
                          fields={FormFields}
                          formtagclassname="team-form"
                          to_email_id={teamData?.email}
                        />
                      </div>
                    </Modal.Body>
                  </Modal>
      </Container>
    </div>
  )
}

export default TeamBannerComponent
