import { Link, graphql, useStaticQuery } from "gatsby"
import React from "react"
import BreadcrumbModule from "../components/BreadcrumbModule/BreadcrumbModule"
import Layout from "../components/layout"
import TeamBannerComponent from "../components/TeamBannerComponent/TeamBannerComponent"
import TeamSlider from "../components/TeamSlider/TeamSlider"
import { PageLinks } from "../common/site/page-static-links";
import Seo from "../components/seo"
import { homeValuation, instantValuation } from "../site/urls"
const TeamDetail = ({ data }) => {
  const teamData = data?.strapiTeam
  const metaTitle=teamData?.name;
  const metaDescription=`Get to know about ${metaTitle} here. Contact one of our estate agents for assistance in finding the right property for you.`
  return (
    <Layout className={"team-details-page-layout"} popularSearch={teamData?.select_popular_search?.title}>
       <Seo title={metaTitle} description={metaDescription} />
       <div className="bottom-right-image-home">
        <div className="hover-block">
          <div className="block-elements">
           {/* <Link to={instantValuation}>Instant Online Valuation</Link>
           <div className="vertical-line"></div> */}
           <Link to={homeValuation}>Home Visit Valuation</Link>
           </div>
        </div>
        <div className="banner-img"></div>
        </div>
      <div className="team-details-wrapper">
      <BreadcrumbModule mainparentname={`About`} mainparent={PageLinks.about} parentname={`Our People`} parent={PageLinks.team} pagename={teamData?.name} />
        <TeamBannerComponent teamData={teamData} />
        <TeamSlider />
      </div>
    </Layout>
    // <></>
  )
}

export default TeamDetail

export const query = graphql`
  query ($page_id: Int) {
    strapiTeam(strapi_id: { eq: $page_id }) {
      ...TeamFragment
    }
  }
`
